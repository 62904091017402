import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const Plans = () => {
  const [showFreeWeekPass, setShowFreeWeekPass] = useState(false);
  const [showMonthlyPlan, setShowMonthlyPlan] = useState(false);
  const [showYearlyPlan, setShowYearlyPlan] = useState(false);

  const freeWeekPassDetails = {
    price: "Free",
    features: [
      "One week (valid local ID required)",
      "Full gym access",
      "Unlimited classes",
    ],
  };

  const monthlyPlanDetails = {
    price: (
      <>
      <strong>1 month</strong> - $70 | <strong>3 month</strong> - $180
      </>
    ),
    features: ["Full gym access", "Unlimited classes"],
  };

  const yearlyPlanDetails = {
    price: (
      <>
        <strong>1 year</strong> - $40/month | <strong>2 year</strong> -
        $30/month ($75 initiation fee)
      </>
    ),
    features: ["Full gym access", "Unlimited classes"],
  };

  return (
    <div id="membership-plans-section">
      <h3
        style={{
          textAlign: "center",
          fontSize: "1.8rem",
          letterSpacing: "2px",
          marginTop: "5rem",
        }}
      >
        Membership Plans
      </h3>
      <h2 style={{ marginLeft: "2rem", letterSpacing: "2px" }}>
        Choose a plan
        <span
          style={{
            background: "linear-gradient(90deg, #de6f23, #de6f23)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: "bold",
          }}
        >
          {" "}
          for you.
        </span>
      </h2>
      <div>
        <PlanCard
          title="Free Week Pass"
          details={freeWeekPassDetails}
          isVisible={showFreeWeekPass}
          onClick={() => setShowFreeWeekPass(!showFreeWeekPass)}
        />
        <PlanCard
          title="Monthly Membership"
          details={monthlyPlanDetails}
          isVisible={showMonthlyPlan}
          onClick={() => setShowMonthlyPlan(!showMonthlyPlan)}
        />
        <PlanCard
          title="Yearly Membership"
          details={yearlyPlanDetails}
          isVisible={showYearlyPlan}
          onClick={() => setShowYearlyPlan(!showYearlyPlan)}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <p>
          We want to work with you. For any questions about pricing and other
          plans please reach out by phone or by using the contact form below.
        </p>
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <h4>Other Offers Include:</h4>
        <p>Family member plans, $10 day pass</p>
      </div>
    </div>
  );
};

const PlanCard = ({ title, details, isVisible, onClick }) => {
  return (
    <Card
      style={{
        margin: "10px",
        borderRadius: "10px",
        border: "1px solid #ddd",
      }}
    >
      <CardContent>
        <h4 style={{ color: isVisible ? "white" : "black" }}>{title}</h4>
        <Button
          onClick={onClick}
          variant="contained"
          style={{
            backgroundColor: isVisible ? "white" : "#de6f23",
            color: isVisible ? "#de6f23" : "white",
          }}
        >
          {isVisible ? "Hide Details" : "Show Details"}
        </Button>
        {isVisible && (
          <div>
            <p>Price: {details.price}</p>
            <ul>
              {details.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Plans;
