import React from "react";
import { Button } from "@mui/material";
import { East, QuestionAnswer } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Column = () => {
  const isMobile = window.innerWidth <= 600;
  const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;

  const nav = useNavigate();

  const columnStyle = {
    display: "flex",
    flexDirection: isMobile || isTablet ? "column" : "column", // Set to 'row' for non-mobile and non-tablet devices
    justifyContent: "center",
    alignItems: "center",
  };

  const rowStyle = {
    display: "flex",
    flexDirection: isMobile || isTablet ? "column" : "",
  };

  const boxStyle = {
    padding: "15px",
    margin: "25px",
    backgroundColor: "#151515",
    borderRadius: "10px",
    width: "280px",
    height: "240px",
  };

  const handleNavToTrainers = () => {
    nav("our-trainers");
    window.scrollTo(0, 0);
  };

  const handleNavToClasses = () => {
    nav("health-club");
    window.scrollTo(0, 0);
  };

  const handleNavToContact = () => {
    nav("contact");
    window.scrollTo(0, 0);
  };

  const handleNavToTherapy = () => {
    nav("health-club");

    // Get the height of the entire document
    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    // Scroll to the bottom with smooth animation
    window.scrollTo({
      top: documentHeight - 50,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <h2 style={{ marginLeft: "2rem", letterSpacing: "2px" }}>
        At Our
        <span
          style={{
            background: "linear-gradient(90deg, #de6f23, #de6f23)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: "bold",
          }}
        >
          {" "}
          Health Club.
        </span>
      </h2>

      <div style={columnStyle}>
        <div style={rowStyle}>
          <div style={boxStyle}>
            {/* <img src={pt} style={{width: '100px'}} alt="" /> */}
            <h2 style={{ color: "#ff8c00", marginTop: "1rem" }}>
              Personal Training
            </h2>
            <p style={{ color: "white" }}>
              Our certified trainers are dedicated to helping you reach your
              health and fitness goals, whether you're a seasoned athlete or
              just starting your fitness journey. We provide all you need for
              success.
            </p>
            <div style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                onClick={handleNavToTrainers}
                style={{ backgroundColor: "#de6f23" }}
                endIcon={<East />}
              >
                Learn more
              </Button>
            </div>
          </div>
          <div style={boxStyle}>
            <h2 style={{ color: "#ff8c00", marginTop: "1rem" }}>
              Massage Therapy
            </h2>
            <p style={{ color: "white" }}>
              Licensed massage therapist Michelle Robison enhances well-being
              through personalized massage programs. Graduate of the American
              Institute of Alternative Medicine. Book an appointment on her
              website.
            </p>
            <div style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                onClick={handleNavToTherapy}
                style={{ backgroundColor: "#de6f23" }}
                endIcon={<East />}
              >
                Learn more
              </Button>
            </div>
          </div>

          <div style={boxStyle}>
            <h2 style={{ color: "#ff8c00", marginTop: "10px" }}>
              Spinning & Group Classes
            </h2>
            <p style={{ color: "white" }}>
              Join our fun and energetic group classes led by certified
              instructors. Our classes cater to all fitness levels and are
              included in membership prices.
            </p>
            <div style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                onClick={handleNavToClasses}
                style={{ backgroundColor: "#de6f23" }}
                endIcon={<East />}
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={boxStyle}>
            <h2 style={{ color: "#ff8c00", marginTop: "10px" }}>
              State-of-the-Art Facilities
            </h2>
            <p style={{ color: "white" }}>
              Our full-service gym offers quality equipment, spacious workout
              areas, lockers, showers, and a circuit of machines, free weights,
              and cardio equipment. Plus, free towel service.
            </p>
            <div style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                onClick={handleNavToClasses}
                style={{ backgroundColor: "#de6f23" }}
                endIcon={<East />}
              >
                Learn more
              </Button>
            </div>
          </div>
          <div style={boxStyle}>
            <h2 style={{ color: "#ff8c00" }}>Silver Sneakers Certified</h2>
            <p style={{ color: "white" }}>
              Silver Sneakers fitness program is designed for adults, typically
              65 years and older. Offering a variety of exercise classes,
              wellness resources, and social activities to help seniors maintain
              their health.
            </p>
            <div>
              <Button
                variant="contained"
                onClick={handleNavToContact}
                style={{ backgroundColor: "#de6f23" }}
                endIcon={<QuestionAnswer />}
              >
                Questions?
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Column;
